import type { Schemas } from "#shopware";

export default function useGtmTracking() {
  const trackEvent = (event: string, data: Record<string, any> = {}) => {
    if (process.client) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event, ecommerce: data });
    }
  };

  const trackViewItem = (product: Ref<Schemas["Product"]>) => {
    trackEvent("view_item", {
      items: [
        {
          item_id: product.id,
          item_name: product?.name,
          price: product?.calculatedPrice?.unitPrice,
          currency: "EUR",
        },
      ],
    });
  };

  const trackAddToCart = (product: Schemas["Product"], quantity: number) => {
    trackEvent("add_to_cart", {
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          price: product?.calculatedPrice?.unitPrice,
          quantity: quantity,
          currency: "EUR",
        },
      ],
    });
  };

  const trackBeginCheckout = (cartItems: Schemas["LineItem"][]) => {
    trackEvent("begin_checkout", {
      items: cartItems.map((item) => ({
        item_id: item.id,
        item_name: item.label,
        price: item.price.unitPrice,
        quantity: item.quantity,
        currency: "EUR",
      })),
    });
  };

  const trackAddShippingInfo = (shippingMethod: Schemas["ShippingMethod"]) => {
    trackEvent("add_shipping_info", {
      shipping_tier: shippingMethod,
    });
  };

  const trackAddPaymentInfo = (paymentMethod: Schemas["PaymentMethod"]) => {
    trackEvent("add_payment_info", {
      payment_type: paymentMethod,
    });
  };

  const trackPurchase = (order) => {
    trackEvent("purchase", {
      order_number: order.value?.orderNumber,
      transaction_id: order.value?.id,
      value: order.value?.price?.totalPrice,
      shipping_cost: order.value?.shippingTotal,
      tax_cost: order.value?.price?.calculatedTaxes?.[0]?.tax,
      currency: "EUR",
      items: order.value?.lineItems?.map((item) => ({
        item_id: item.id,
        item_name: item.label,
        price: item.unitPrice,
        quantity: item.quantity,
      })),
    });
  };

  return {
    trackEvent,
    trackViewItem,
    trackAddToCart,
    trackBeginCheckout,
    trackAddShippingInfo,
    trackAddPaymentInfo,
    trackPurchase,
  };
}
